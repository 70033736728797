<template>
  <div class="navWrapper">
    <el-menu @select="handleSelect" :router="true">
      <el-menu-item id="logo" index="/" @click="hideMenu"  :route="{ name: 'Root'}">
        <logo></logo>
      </el-menu-item>
    </el-menu>
    <div id="menu" :class="{ active: isActive }">
      <el-menu v-if="!isActive" mode='horizontal' active-text-color="#efefef" @select="handleSelect" :ellipsis="false" :router="true">
        <el-menu-item index="/general" :route="{ name: 'General' }">
          Главная
        </el-menu-item>
        <el-menu-item index="/projects" :route="{ name: 'Projects' }">
          Услуги
        </el-menu-item>
        <el-menu-item index="/about" :route="{ name: 'About' }">
          Команда
        </el-menu-item>
        <el-menu-item index="/contact" :route="{ name: 'Contact' }">
          Партнеры
        </el-menu-item>
        <el-menu-item index="/contact" :route="{ name: 'Contact' }">
          FAQ
        </el-menu-item>
      </el-menu>
    </div>
    <div id="toggle" @click="select()">
      <div class="span" id="top" :class="{ active: isActive }"></div>
      <div class="span" id="middle" :class="{ active: isActive }"></div>
      <div class="span" id="bottom" :class="{ active: isActive }"></div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'

export default {
  components: {
    Logo
  },

  data() {
    return {
      activeIndex: '/general',
      isActive: false
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
    select: function() {
      this.isActive = !this.isActive;
    },
    hideMenu: function() {
      this.isActive = false
    }
  }
}
</script>
