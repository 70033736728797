<template>
  <div class="parent" >
    <div class="child" v-for="service in services">
      <img :src="service.file" alt="" class="service-img">
      <p class="service_title">{{$t(service.title)}}</p>
      <p class="service_description">{{$t(service.description)}}</p>
      <div class="button">
        <a target="_blank" @click="newService(service)" >{{ $t('read_more') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Service",
  data: function () {
    return {
      services: [
        {
          id: 1,
          title : "accounting",
          description: "accounting_description",
          file: require('../assets/icons/services/accounting.jpg'),
        },
        {
          id: 2,
          title : "consultation",
          description: "consultation_description",
          file: require('../assets/icons/services/consulting.jpg'),
        },
        {
          id: 3,
          title : "legal_services",
          description: "legal_services_description",
          file: require('../assets/icons/services/legal_service.jpg'),
        },
        {
          id: 4,
          title : "other_service",
          description: "other_service_description",
          file: require('../assets/icons/services/other_service.jpg'),
        }
      ]

    }
  },
  methods:{
    newService(service){
      this.$router.push({ path: '/services/' + service.id, query: { data: JSON.stringify(service) } });
      window.scrollTo(0, 0);
    }
  },
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  @import "service.css";
</style>