<template>
  <div class="navWrapper" ref="navWrapper" style="width: 100%">
    <el-menu @select="handleSelect" :router="true" background-color="transparent">
      <el-menu-item id="logo" index="/" @click="hideMenu"   :route="{ name: 'Root'}">
        <img src="../assets/logos/bc_text.svg" alt="logo" class="img-logo" @click="memberScroll('navWrapper')">
      </el-menu-item>
    </el-menu>
    <div id="menu" style="width: 100%">
      <el-menu v-if="!isActive" mode='horizontal' background-color="transparent" active-text-color="#ffffff" @select="handleSelect" :ellipsis="false" :router="true" style="align-items: center">
        <div class="menu">
          <el-menu-item  :route="{ name: 'General' }" @click="memberScroll('generalBox')">
            {{$t('general')}}
          </el-menu-item>
          <el-menu-item :route="{ name: 'Services' }"  @click="memberScroll('servicesComponent')" class="dropdown">
            <div class="dropbtn">{{$t('services')}}</div>
<!--            <div class="dropdown-content" >-->
<!--              <div v-for="service in services">-->
<!--                <a href="{{service.url}}" target="_blank">{{ $t(service.title) }}</a>-->
<!--              </div>-->
<!--              <a href="#/services" target="_blank">Service 2</a>-->
<!--              <a href="#/services" target="_blank">Service 3</a>-->
<!--            </div>-->
          </el-menu-item>
          <el-menu-item :route="{ name: 'Team' }"  @click="memberScroll('memberComponent')">
            {{$t('team')}}
          </el-menu-item>
          <el-menu-item :route="{ name: 'Partners' }"  @click="memberScroll('partnersComponent')">
            {{$t('partners')}}
          </el-menu-item>
          <el-menu-item :route="{ name: 'FAQ' }"  @click="memberScroll('faqComponent')">
            FAQ
          </el-menu-item>
        </div>
        <el-menu-item style="margin: 0 100px 0 auto" class="select_menu">
          <select v-model="$i18n.locale" @change="changeLocale" class="select">
            <option value="hy">{{ $t('armenian') }}</option>
            <option value="ru">{{ $t('russian') }}</option>
<!--            <option value="en">{{ $t('english') }}</option>-->
          </select>
        </el-menu-item>
      </el-menu>
    </div>
<!--    <div id="toggle" @click="select()">-->
<!--      <div class="span" id="top" :class="{ active: isActive }"></div>-->
<!--      <div class="span" id="middle" :class="{ active: isActive }"></div>-->
<!--      <div class="span" id="bottom" :class="{ active: isActive }"></div>-->
<!--    </div>-->
  </div>
</template>

<script>

import VueScrollTo from 'vue-scrollto';

export default {
  name: "NavComponent",
  data: function () {
    return {
      isActive: false,
      services: [
        {
          title : "accounting",
          description: "slider_description",
          url: "#/services",
          file: require('../assets/icons/service2.jpeg'),
        },
        {
          title : "consultation",
          description: "slider_description",
          url: "#/services",
          file: require('../assets/icons/service4.jpeg'),
        },
        {
          title : "other_service",
          description: "slider_description",
          url: "#/services",
          file: require('../assets/icons/service2.jpeg'),
        }
      ]
    }
  },
  props: [
    'refScroll',
  ],
  methods: {
    async memberScroll(refName) {
      if (this.$route.path !== '/'){
        await this.$router.push({ path: '/'});
        setTimeout(() => {
          this.$emit('scrollRequest', refName);
        }, 500)

      }
      this.$emit('scrollRequest', refName);
    },
    hideMenu: function () {
      this.isActive = false
      const duration = 500;
      const scrollStep = -window.scrollY / (duration / 15);

      const scrollInterval = setInterval(() => {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        } else {
          clearInterval(scrollInterval);
        }
      }, 25);

    },
    changeLocale(locale) {
      this.$i18n.locale = locale.target.value;
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath);
    },
  }
}
</script>

<style scoped>
@import "Nav.css";
</style>
