<template>
  <div class="general">
    <div class="img-icon">
<!--      <img src="../../assets/logos/longLogo.svg" alt="logo">-->
    </div>
    <div class="about">
      <div>
        <h3>
          <span class="our-company">НАША КОМПАНИЯ В АРМЕНИИ</span>
          предоставляет следующие услуги:
        </h3>
      </div>
      <div class="points">
        <p class="points-p">Все вышеперечисленные пункты касаются как резидентов, так и нерезидентов РА.</p>
      </div>
      <div class="list-contacts">
        <div class="list">
          <p class="ordinal">
            <span class="material-icons">
              done
            </span>
            Регистрация компании
          </p>
          <p class="ordinal">
            <span class="material-icons">
              done
            </span>
            Бухгалтерские услуги
          </p>
          <p class="ordinal">
            <span class="material-icons">
              done
            </span>
            Открытие банковского счета
          </p>
        </div>
        <div class="contacts">
          <div class="contacts-list">
            <div class="contacts-img">
<!--              <img src="../../assets/icons/pickUpPoint.svg" alt="address">-->
            </div>
            <p>1 North Avenue, Yerevan</p>
          </div>
          <div class="contacts-list">
            <p class="phone">
              <span class="material-icons">
                call
              </span>
            </p>
            <div class="phone-number">
              <p> +374 94082118, +374 96082118</p>
            </div>
          </div>
          <div class="contacts-list">
            <div class="contacts-img">
              <a href="https://www.facebook.com/Best-Consulting-Co-LLC-108935243846143/">
<!--                <img src="../../assets/icons/facebook.svg" alt="facebook">-->
              </a>
            </div>
            <div class="contacts-img">
              <a href="https://telegram.me/Mariam_BestCo">
<!--                <img src="../../assets/icons/telegram.svg" alt="telegram">-->
              </a>
            </div>
            <div class="contacts-img">
              <a href="https://www.instagram.com/bestconsultingco/">
<!--                <img src="../../assets/icons/instagram.svg" alt="instagram">-->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CutawayComponent"
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.general {
  width: 100%;
  height: 850px;
  background: #cedddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img-icon {
  width: 100%;
  height: 155px;
}
img {
  width: 100%;
  height: 100%;
}
.about {
  margin: 25px 0;
}
.contacts {
  font-size: 16px;
}

.phone-number {
  display: block;
}
p {
  font-size: 16px;
}
pre {
  font-size: 16px;
}
.icon {
  width: 50px;
  height: 50px;
}
.our-company {
  color: #37848d;
}
h3 {
  color: #536786;
}
.points {
  margin: 10px 0;
}
.points3 {
  margin: 10px 0 10px 41px;
}
.points-p {
  color: #536786;
}
.contacts-img {
  width: 16px;
  margin-right: 6px;
}
.contacts-img > img {
  width: 100%;
  height: 100%;
}
.material-icons {
  color: #536786;
  margin-right: 2px;
}
.telegram-img {
  width: 20px;
  color: red;
}
.list{
}
.ordinal {
  height: 25px;
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.list-contacts {
  display: flex;
  margin-top: 23px;
  justify-content: space-between;
}
.contacts-list {
  display: flex;
  margin-top: 4px;

}
@media (max-width: 400px) {
  .list-contacts {
    display: block;
  }
  .contacts {
    margin-top: 20px;
  }
}
</style>
