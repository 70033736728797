export const ruLocale = {
    'name' : "name ru",
    'general' : 'Главная',
    'services' : 'Услуги',
    'team' : 'Команда',
    'partners' : 'Партнеры',
    'armenian' : 'Арм',
    'russian' : 'Рус',
    'english' : 'Англ',
    'accounting' : 'Бухгалтерский учет',
    'consultation' : 'Консультация',
    'legal_services' : 'Юридические услуги',
    'other_service' : 'Прочие услуги',

    'accounting_description' : 'Разработка политики бухгалтерского учета...',
    'consultation_description' : 'Консультации по бухгалтерским формулировкам...',
    'legal_services_description' : 'Регистрация юридического лица...',
    'other_service_description' : 'Составление необходимых документов...',

    'read_more': 'Читать далее',


    // services
    'accounting_1': 'Разработка политики бухгалтерского учета',
    'accounting_2': 'Составление бухгалтерских отчетов',
    'accounting_3': 'Консультации по бухгалтерскому и налоговому учету, оформление начальных документов',
    'accounting_4': 'Обеспечение документооборота',
    'accounting_5': 'Планирование налогов, штрафов и других обязательных',
    'consultation_1': 'Консультации по бухгалтерским формулировкам',
    'consultation_2': 'Налоговые консультации по вопросам практического применения налогового законодательства и внесенных изменений',
    'consultation_3': 'Анализ и консультации по бизнес-планам',
    'consultation_4': 'Уточнение вопросов, связанных с учреждением организации',

    'legal_services_1': 'Регистрация юридического лица в Государственном реестре РА',
    'legal_services_2': 'Купля-продажа акции',
    'legal_services_3': 'Изменение юридического адреса',
    'legal_services_4': 'Изменение Устава',

    'other_service_1': 'Составление необходимых документов для получения Сертификат о государственной поддержке сферы информационных технологий (ИТ-сертификата), подача заявления',
    'other_service_2': 'Сбор документов на вид на жительство, подача заявления.',

    // team members
    'hayk_shaboyan': "Айк Шабоян",
    'mariam_aghabekyan': "Мариам Агабекян",
    'diana_poghosyan': "Диана Погосян",
    'ruzanna_piliposyan': "Рузанна Пилипосян",
    'vahe_abrahamyan': "Ваге Абраамян",
    'eleonora_sargsyan': "Элеонора Саргсян",

    // professions
    'founder': 'Учредитель',
    'director': 'Директор',
    'accountant': 'Бухгалтер',
    'junior_consultant': 'Младший консультант',

    // text
    'meet_our_team': 'Познакомьтесь с нашей командой',
    'our_experts_are_ready_to_study_your_case_and_help_you_achieve_the_desired_result': 'Наши эксперты в области технологий готовы изучить ваше дело и помочь вам достичь желаемой цели',
    'during_its_activity': 'За время своей деятельности компания «Best Co» успела установить сотрудничество с рядом компаний.',
    'the_most_optimal_tax_policy': 'Самая оптимальная налоговая политика',
    'we_offer_accounting': 'Мы предлагаем бухгалтерские, консультационные, юридические и финансовые услуги всем резидентным и нерезидентным организациям.',

    //FAQ

    'faq_1': 'Какие документы необходимо представить в Государственный реестр юридических лиц РА для регистрации ИП?',
    'faq_1_answer': 'Для регистрации ИП необходимо предъявить паспорт (желательно заграничный) и нотариально заверенный перевод паспорта.',
    'faq_2': 'Нужно ли иметь оборот для получения статуса временного проживания?',
    'faq_2_answer': 'Нет, все физ. лицы, занимающиеся предпринимательской деятельностью, могут подать заявление на получение статуса временного проживания.',
    'faq_3': 'Сколько времени нужно находится в Армении, чтобы открыть ИП (ООО) и счет в банке?',
    'faq_3_answer': 'В среднем необходимо находиться в Армении 10 рабочих дней.',

    //footer

    'address': 'Аваг Петросян 4',
    'join_us': 'Свяжитесь с нами',
}