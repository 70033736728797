import {createRouter, createWebHashHistory} from "vue-router";
import AppLayout from '../views/layouts/AppLayout'
// eslint-disable-next-line no-unused-vars
import Hello from '../views/hello/index'
import General from '../views/general/index'


export const routes = [

  {
    path: '/',
    name: 'Root',
    component: AppLayout,
  },
  {
    path: '/services/:id',
    name: 'Services',
    component: () => import('@/views/services/Service'),
    meta: { title: 'Service' }
  },
  {
    path: '/home',
    component: AppLayout,
    name: 'Home',
    children: [
      {
        path: '/hello',
        name: 'Hello',
        component: () => import('@/views/hello/index'),
        meta: { title: 'Hello' }
      },

    ]
  }
]
const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router






