<template>
  <div class="app-wrapper">

  <General></General>
<!--  <div class="main-container">-->
<!--    <app-content></app-content>-->
<!--  </div>-->
  </div>
</template>

<script>
import { Navbar, AppContent } from '@/views/layouts/components'
import General from '@/views/general/index'

export default {
  name: 'AppLayout',
  components: {
    Navbar,
    AppContent,
    General
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.main-container {
  text-align: center;

}
</style>
