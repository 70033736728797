export const enLocale = {
    'name' : 'name en',
    'general' : 'General',
    'services' : 'Services',
    'team' : 'Team',
    'partners' : 'Partners',
    'armenian' : 'Hy',
    'russian' : 'Ru',
    'english' : 'En',
    'accounting' : 'Accounting',
    'consultation' : 'Consultation',
    'other_service' : 'Other service',
    'slider_description' : 'Development of accounting policy...',
    'read_more': 'Read more',

    // services
    'accounting_1': 'Հաշվապահական հաշվառման քաղաքականության մշակում',
    'accounting_2': 'Հաշվապահական հաշվետվությունների կազմում',
    'accounting_3': 'Հաշվապահական և հարկային հաշվապահության խորհրդատվություն, սկզբնական փաստաթղթերի մշակում',
    'accounting_4': 'Փաստաթղթաշրջանառության ապահովում',
    'accounting_5': 'Հարկերի, տուրքերի և այլ պարտադիր վճարների պլանավորում',
    'consultation_1': 'Հաշվապահական ձևակերպումների գծով խորհրդատվություն',
    'consultation_2': 'Հարկային խորհրդատվություն` հարկային օրենսդրության գործնական կիրառման և կատարված փոփոխությունների վերաբերյալ',
    'consultation_3': 'Բիզնես ծրագրերի գծով վերլուծություն և խորհրդատվություն',
    'consultation_4': 'Կազմակերպության հիմնադրման հետ առնչվող հարցերի պարզաբանում',
    'other_service_1': 'Տեղեկատվական տեխնոլոգիաների ոլորտի պետական աջակցության Հավաստագրի (IT հավաստագիր) ստացման համար անհրաժեշտ փաստաթղերի կազմում, դիմումի ներկայացում',
    'other_service_2': 'Կացության կարգավիճակի համար փաստաթղթերի հավաքագրում, դիմումի հերկայացում',

    // team members
    'hayk_shaboyan': "Hayk Shaboyan",
    'mariam_aghabekyan': "Mariam Aghabekyan",
    'diana_poghosyan': "Diana Poghosyan",
    'ruzanna_piliposyan': "Ruzanna Piliposyan",
    'vahe_abrahamyan': "Vahe Abrahamyan",
    'eleonora_sargsyan': "Eleonora Sargsyan",


    // professions
    'founder': 'Founder',
    'director': 'Director',
    'accountant': 'Accountant',
    'junior_consultant': 'Junior consultant',

    // text
    'meet_our_team': 'Meet our team',
    'our_experts_are_ready_to_study_your_case_and_help_you_achieve_the_desired_result': 'Our experts are ready to study your case and help you achieve the desired result.',
    'during_its_activity': 'During its activity, "Best Consulting Co" managed to establish long lasting cooperations with a number of companies.',
    'the_most_optimal_tax_policy': 'The most optimal tax policy',
    'we_offer_accounting': 'We offer accounting, consulting, legal and financial services to all resident and non-resident organisations.',


    //address

    'address': 'Avag Petrosyan 4',
    'join_us': 'Join us',
}