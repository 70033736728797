<template>
  <div class="workers-header">
    <h3>{{ $t('meet_our_team') }}</h3>
    <p>{{ $t('our_experts_are_ready_to_study_your_case_and_help_you_achieve_the_desired_result') }}</p>
  </div>
  <div class="workers">
<!--    <div>-->
      <div v-for="sidebarList in sidebarLists" :class="`worker${sidebarList.id}`" id="members">
        <div class="member-img">
          <img class="worker-img" :src="sidebarList.image" :alt="$t(sidebarList.name)">
        </div>
        <div class="worker-info">
          <h4>{{ $t(sidebarList.name) }}</h4>
          <h5 class="worker-profession" style="color: #243858">{{ $t(sidebarList.profession) }}</h5>
          <!--        <h5 class="worker-position" style="color: #243858">{{ sidebarList.worker }} kkk</h5>-->
          <a :href=sidebarList.telegramLink class="telegram_link" target="_blank">
            <img src="../assets/icons/telegram.svg" alt="telegram" class="img_telegram">
          </a>
        </div>
      </div>
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "TeamMembers",

  data: () => ({
    sidebarLists: [
      {
        id: 1,
        name: 'hayk_shaboyan',
        // fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/https://t.me/Hayk_BestCo',
        image: require('./images/Hayk.jpg'),
        profession: 'founder',
      },
      {
        id: 2,
        name: 'mariam_aghabekyan',
        fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/Mariam_BestCo',
        image: require('./images/mariam.jpg'),
        profession: 'director',
      },
      {
        id: 3,
        name: 'diana_poghosyan',
        // fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/Diana_Poghosyan',
        image: require('./images/diana.jpeg'),
        profession: 'accountant',
      },
      {
        id: 4,
        name: 'ruzanna_piliposyan',
        // fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/piliposyanruz',
        image: require('./images/ruzanna.jpg'),
        profession: 'accountant',
      },
      {
        id: 5,
        name: 'vahe_abrahamyan',
        // fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/Vahe_05',
        image: require('./images/vahe.jpg'),
        profession: 'junior_consultant',
      },
      {
        id: 6,
        name: 'eleonora_sargsyan',
        // fbLink: 'https://www.facebook.com/mariam.aghabekyan.50',
        telegramLink: 'https://www.telegram.me/ellsargsyann',
        image: require('./images/elya.jpg'),
        profession: 'junior_consultant',
      }
    ],
  }),

}
</script>

<style scoped>
@import "TeamMemers.css";
</style>
