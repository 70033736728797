<template>
  <div class="content-container">
    <div class="hello-wrapper">
      <div class="hello-background">
        <div class="content">
          <h2>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consectetur doloremque eveniet illum iure laboriosam natus omnis optio possimus quam qui quo quod sed tenetur, totam vel vero voluptas, voluptate.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.hello-wrapper{
  width: 100%;
  height: 100vh;
  background: url("https://media-www.sqspcdn.com/images/pages/vertical/small-business/hero/background-2500w.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}
.hello-background{
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  display: flex;
  align-items: center;
}
.content{
  padding-left: 40px;
  text-align: left;
  width: 60%;
}
.hello-background h2{
  color: #efefef;
  font-size: 54px;
}
.content p{
  margin-top: 25px;
  width: 65%;
  line-height: 25px;
}
@media screen and (max-width: 976px){
  .content{
    margin: 0 auto;
    text-align: center;
  }
  .content h2{
    font-size: 42px;
  }
  .content p{
    margin: 15px auto 0 auto;
  }
}
@media screen and (max-width: 976px) {
  .content{
    width: 90%;
  }
  .content h2 {
    font-size: 36px;
  }

  .content p {
    font-size: 14px;
  }
}

</style>

<script>
export default {
  data() {
    return {
      title: 'Best Co ;)'
    }
  }
}
</script>
