<template style="height: 100vh">
  <NavComponent @scrollRequest="handleScrollRequest" />
  <div class="container" ref="generalBox">
    <div class="home general-box">
      <div class="home-text">
        <h1>
          {{ $t('the_most_optimal_tax_policy')}}
        </h1>
        <h4>
          {{ $t('we_offer_accounting')}}
        </h4>
      </div>
      <div class="home-img">
        <!--                <img src="../assets/images/general-background.jpg" alt="" class="img">-->
      </div>
    </div>
  </div>
  <div ref="servicesComponent">
    <Service/>
  </div>
  <div ref="faqComponent">
    <FAQComponent/>
  </div>
  <div ref="partnersComponent">
    <PartnersComponent/>
  </div>
  <div ref="memberComponent">
    <TeamMembers/>
  </div>
  <div>
    <GoogleMap api-key="AIzaSyDBozXQNK2QymhGgP9uxW2nzaAipTeYIBM" style="width: 100%; height: 300px" :center="center"
               :zoom="15">
      <Marker :options="{ position: center }"/>
    </GoogleMap>
  </div>

  <!--Markers-->
  <FooterComponent/>
  <!--      <CutawayComponent/>-->
</template>

<script>
import Logo from '@/components/Logo'
import NavComponent from "../header/NavComponent"
import FooterComponent from "../footer/FooterComponent"
import Service from "../services/Services"
import FAQComponent from "../FAQ/FAQComponent"
import PartnersComponent from "../partners/PartnersComponent"
import TeamMembers from "../TeamMembers/TeamMembers"
import {GoogleMap, Marker} from "vue3-google-map";
import CutawayComponent from "../cutaway/CutawayComponent"
import VueScrollTo from 'vue-scrollto';

export default {
  el: '#app',
  name: 'General',
  components: {
    Logo,
    NavComponent,
    FAQComponent,
    FooterComponent,
    Service,
    PartnersComponent,
    TeamMembers,
    GoogleMap,
    Marker,
    CutawayComponent,
  },

  data() {
    return {
      activeIndex: '/general',
      isActive: false,
      element: '',

      zoom: 12,
      center: {lat: 40.18061514628466, lng: 44.52160810651501},
      refScrollValue: null,
      languages: [
        {
          value: 'hy',
          name: "HY"
        },
        {
          value: 'en',
          name: "EN"
        },
        {
          value: 'ru',
          name: "RU"
        },
      ],

    }
  },

  methods: {
    handleScrollRequest(refName) {
      VueScrollTo.scrollTo(this.$refs[refName], 500, { offset: -150 });
    },
    select: function () {
      this.isActive = !this.isActive;
    },
    mouseover: function () {
      this.isOpen = true;
    },
    mouseleave: function () {
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>
@import "general.css";
</style>
