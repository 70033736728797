export const hyLocale = {
    'name' : "name hy",
    'general' : 'Գլխավոր',
    'services' : 'Ծառայություններ',
    'team' : 'Մեր թիմը',
    'partners' : 'Գործընկերներ',
    'armenian' : 'հայ',
    'russian' : 'Ռուս',
    'english' : 'Անգլ',
    'accounting' : 'Հաշվապահական հաշվառում',
    'consultation' : 'Խորհրդատվություն',
    'legal_services' : 'Իրավաբանական ծառայություններ',
    'other_service' : 'Այլ ծառայություններ',

    'accounting_description' : 'Հաշվապահական հաշվառման քաղաքականության մշակում...',
    'consultation_description' : 'Հաշվապահական ձևակերպումների գծով խորհրդատվություն...',
    'legal_services_description' : 'Իրավաբանական անձի գրանցում ՀՀ պետական ռեգիստրում...',
    'other_service_description' : 'Տեղեկատվական տեխնոլոգիաների ոլորտի պետական աջակցության...',

    'read_more': 'Կարդալ ավելին',

    // services
    'accounting_1': 'Հաշվապահական հաշվառման քաղաքականության մշակում',
    'accounting_2': 'Հաշվապահական հաշվետվությունների կազմում',
    'accounting_3': 'Հաշվապահական և հարկային հաշվապահության խորհրդատվություն, սկզբնական փաստաթղթերի մշակում',
    'accounting_4': 'Փաստաթղթաշրջանառության ապահովում',
    'accounting_5': 'Հարկերի, տուրքերի և այլ պարտադիր վճարների պլանավորում',
    'consultation_1': 'Հաշվապահական ձևակերպումների գծով խորհրդատվություն',
    'consultation_2': 'Հարկային խորհրդատվություն` հարկային օրենսդրության գործնական կիրառման և կատարված փոփոխությունների վերաբերյալ',
    'consultation_3': 'Բիզնես ծրագրերի գծով վերլուծություն և խորհրդատվություն',
    'consultation_4': 'Կազմակերպության հիմնադրման հետ առնչվող հարցերի պարզաբանում',

    'legal_services_1': 'Իրավաբանական անձի գրանցում ՀՀ պետական ռեգիստրում',
    'legal_services_2': 'Բաժնեմասի առուվաճառք',
    'legal_services_3': 'Իրավաբանական հասցեի փոփոխություն',
    'legal_services_4': 'Կանոնադրության փոփոխություն',

    'other_service_1': 'Տեղեկատվական տեխնոլոգիաների ոլորտի պետական աջակցության Հավաստագրի (IT հավաստագիր) ստացման համար անհրաժեշտ փաստաթղերի կազմում, դիմումի ներկայացում',
    'other_service_2': 'Կացության կարգավիճակի համար փաստաթղթերի հավաքագրում, դիմումի հերկայացում',

    // team members
    'hayk_shaboyan': "Հայկ Շաբոյան",
    'mariam_aghabekyan': "Մարիամ Աղաբեկյան",
    'diana_poghosyan': "Դիանա Պողոսյան",
    'ruzanna_piliposyan': "Ռուզաննա Փիլիպոսյան",
    'vahe_abrahamyan': "Վահե Աբրահամյան",
    'eleonora_sargsyan': "Էլեոնորա Սարգսյան",


    // professions
    'founder': 'Հիմնադիր',
    'director': 'Տնօրեն',
    'accountant': 'Հաշվապահ',
    'junior_consultant': 'Կրտսեր խորհրդատու',

    // text
    'meet_our_team': 'Ծանոթացեք մեր թիմի հետ',
    'our_experts_are_ready_to_study_your_case_and_help_you_achieve_the_desired_result': 'Մեր փորձագետները պատրաստ են ուսումնասիրել ձեր գործը և օգնել Ձեզ հասնել ցանկալի արդյունքի։',
    'during_its_activity': 'Իր գործունեության ընթացքում "Best Consulting Co"-ն հասցրել է համագործակցել մի շարք կազմակերպությունների հետ։',
    'the_most_optimal_tax_policy': 'Լավագույն հարկային քաղաքականություն',
    'we_offer_accounting': 'Մենք առաջարկում ենք հաշվապահական, խորհրդատվական, իրավաբանական և ֆինանսական ծառայություններ բոլոր ռեզիդենտ և ոչ ռեզիդենտ կազմակերպություններին։',

    //FAQ

    'faq_1': 'Ի՞նչ փաստաթղթեր է անհրաժեշտ ներայացնել ՀՀ իրավաբանական անձանց պետական ռեգիստր՝ ԱՁ գրանցելու համար:',
    'faq_1_answer': 'ԱՁ գրանցելու համար պետք է ներկայացնել անձնագիրը (ցանկալի է ճամփորդական) և անձնագրի նոտարական թարգմանությունը:',
    'faq_2': 'Ժամանակավոր կացության կարգավիճակ ստանալու համար անհրաժե՞շտ է ունենալ շրջանառություն։',
    'faq_2_answer': 'Ոչ, ժամանակավոր կացության կարգավիճակ ստանալու դիմում կարող են ներկայացնելը ձեռնարկատիրական գործունեությամբ զբաղվող բոլոր անձիք։',
    'faq_3': 'Որքա՞ն ժամանակ է անհրաժեշտ գտնվել Հայաստանում, ԱՁ (ՍՊԸ) և բանկային հաշիվ բացելու համար։',
    'faq_3_answer': 'Միջինում անրհաժեշտ է 10 աշխատանքային օր գտնվել Հայաստանում։',

    //address

    'address': 'Ավագ Պետրոսյան 4',
    'join_us': 'Միացեք մեզ',
}