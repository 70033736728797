// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import router from './router'
import '@/styles/index.scss' // global css
// import locale from 'element-ui/lib/locale/lang/en'
import locale from 'element-plus/lib/locale/lang/en'
import Vue, { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import VueI18n, {createI18n} from 'vue-i18n'
import {enLocale} from "@/assets/locales/enLocale";
import {ruLocale} from "@/assets/locales/ruLocale";
import {hyLocale} from "@/assets/locales/hyLocale";

const i18n =new createI18n({
    legacy: false, // must set to `false`
    locale: 'ru',
    messages: {
        // en: enLocale,
        ru: ruLocale,
        hy: hyLocale
    }
})
createApp(App).use(ElementPlus, { locale }).use(router).use(i18n).mount('#app')

