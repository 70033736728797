<template>
  <div class="logo-header">
    <div id="logo">
      <img src="@/assets/logos/longLogo.svg" alt="Logo">
<!--      <span>Vue.js</span>-->
    </div>
  </div>
</template>

<style scoped>
img {
  border: none;
}
span {
  color: #303133;
  font-size: 20px;
}

#logo img {
  margin-right: 5px;
  width: 240px;
  height: 50px;
}

</style>
