<template>
  <div class="partners-body">
    <div class="text">
      <h1>{{ $t('partners') }}</h1>
      <p class="partners-text">{{ $t('during_its_activity') }}</p>
    </div>
    <div class="all-partners">
      <div class="img-partner" v-for="partner in partners">
        <a>
          <img :src=partner.logo :alt=partner.name class="img">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PartnersComponent",

  data: function () {
    return {
      partners: [
        {
          logo: require('../assets/images/partners/querty.png'),
          url: '',
          name: 'Qwerty'
        },
        {
          logo: require('../assets/images/partners/ends.png'),
          url: 'https://ends.am/',
          name: 'Ends'
        },
        {
          logo: require('../assets/images/partners/Yellow@2x2.svg'),
          url: '',
          name: 'Yellow'
        },

        {
          logo: require('../assets/images/partners/air-cargo.jpeg'),
          url: '',
          name: 'Amsafebridport'
        },
        {
          logo: require('../assets/images/partners/air-travel.jpeg'),
          url: '',
          name: 'Cheapflights'
        },
        //


        //
        // {
        //   logo: require('../assets/images/partners/pession.png'),
        //   url: '',
        //   name: 'PP'
        // },

        //
        // {
        //   logo: require('../assets/images/partners/pessionPP.png'),
        //   url: '',
        //   name: 'PP'
        // },

        {
          logo: require('../assets/images/partners/Ardiktators.png'),
          url: '',
          name: 'PP'
        },

        {
          logo: require('../assets/images/partners/memoryTravel.jpeg'),
          url: '',
          name: 'MemoryTravel'
        },
        {
          logo: require('../assets/images/partners/pessionPartners.jpg'),
          url: '',
          name: 'PP'
        },
      ]
    }
  }
}
</script>

<style scoped>
@import "Partners.css";
</style>
