<template>
  <div  class="faq_body">
    <h3>FAQ</h3>
    <div class="center">
      <vue-accordion
          title-bg-color="transparent"
          title-color="#536786"
          title-hover-color="#6983aa80"
          accordion-width="80%"
          border="none"
          padding="20px"
          :datas="translatedAccordionData"
      >
      </vue-accordion>
    </div>
  </div>

</template>

<script>
import VueAccordion from "accordion-vue";

export default {
    name: "FAQComponent",
    components: {
        VueAccordion
    },

    data() {
        return {
            accordionData: [
                {
                    title: "faq_1",
                    content: "faq_1_answer"
                },
                {
                    title: "faq_2",
                    content: 'faq_2_answer'
                },
                {
                    title: "faq_3",
                    content: "faq_3_answer"
                },
            ]
        }
    },
  computed: {
    translatedAccordionData() {
      return this.accordionData.map(item => {
        return {
          ...item,
          title: this.$t(item.title),
          content: this.$t(item.content)
        }
      });
    }
  }
}
</script>

<style scoped>
@import "FAQCss.css";

.accordion-header {
    padding: 30px;
}
</style>
