<template>
  <div class="body">
    <div class="first-box">
      <div class="features">
<!--        <div class="feature" v-for="feature in features">-->
<!--          <span class="feature-text">-->
<!--           {{$t(feature.name)}}-->
<!--        </span>-->
<!--        </div>-->
      </div>
      <div class="icons">
        <div class="footer-icon" v-for="contactIcon in contactIcons">
          <a :href=contactIcon.link target="_blank">
            <img :src="contactIcon.icon" alt="">
          </a>
        </div>
        <div></div>
      </div>
    </div>
    <div class="second-box">
      <div class="feature-text">
        <p>© 2022 BestCo</p>
      </div>
      <div class="address">
        <div class="contact-us-text">
          <div  class="footer-icon">
            <img src="../assets/footer/point.jpg" alt="address">
          </div>
          <span>
            {{$t('address')}}
          </span>
        </div>
        <div class="contact-us-text">
          <div  class="footer-icon">
            <img src="../assets/footer/email3.jpg" alt="address">
          </div>
          <span>
            info@bestco.am
          </span>
        </div>
        <div class="contact-us-text">
          <div  class="footer-icon">
            <img src="../assets/footer/phone.jpg" alt="address">
          </div>
          <span>
            +374 94082118
          </span>
        </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data: function () {
    return {
      features: [
        {
          name: 'general'
        },
        {
          name: 'services'
        },
        {
          name: 'team'
        },
        {
          name: 'partners'
        },
        {
          name: 'FAQ'
        },
      ],
      contactIcons: [
        {
          icon: require('../assets/footer/facebook.png'),
          link: 'https://www.facebook.com/profile.php?id=100053685174423',
        },
        {
          icon: require('../assets/footer/instagram.png'),
          link: 'https://www.instagram.com/bestconsultingco/',
        },
        {
          icon: require('../assets/footer/telegram.png'),
          link: 'https://www.telegram.me/Mariam_BestCo',
        },
        {
          icon: require('../assets/footer/viber.png'),
          link: 'viber://add?number=+37494082118',
        },
        {
          icon: require('../assets/footer/whatsup.png'),
          link: 'https://wa.me/qr/XMZDQCYN2DR4C1',
        },
      ],
    }
  },
}
</script>

<style scoped>
@import "footerCss.css";
</style>